<template>
	<input class="awesomplete input-tw" v-model="q" @change="loadStats($event)" name="q" list="mylist" placeholder="Digite marca ou modelo do carro" />
    <datalist id="mylist">
        <option v-for="(item, key) in options" :value="item.name_input">
          <span>{{item.name_input}}</span>
        </option>
    </datalist>
</template>
<script>



    export default {
        components: {

        },
        data: function(){
            return {
                q: '',
                options: [],
                loader_options: false
            }
            
        },

        mounted(){
            // this.loadStats();
        },

        methods:{
            loadStats(event){
                axios.get('/api/srall/'+event.target.value)
                .then((response) => {
                    this.options = response.data;
                    this.loader_options = false;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

        }
    }
    
</script>
