<template>
	<div class="grid grid-cols-12">
        <div v-if="loader_mais_buscados" class="col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 xs:col-span-12 padding-col loader-div-new-compoment" style="display: table; height: 350px;">
            <div class="loader loader--style1 loader-new-component" title="0">
                <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
                    <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />
                    <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                    C22.32,8.481,24.301,9.057,26.013,10.047z">
                        <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
        </div>
        <div v-if="!loader_mais_buscados" class="col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6 xs:col-span-6 padding-col" v-for="buscado in buscados_home.slice(0, 6)">
            <div class="col-span-2 lg:col-span-2 md:col-span-4 sm:col-span-6 xs:col-span-12">
                <a :href="`https://www.sulrevendas.com.br/comprar/mais-buscados/${this.sanitizeTitle(buscado)}?template=v2&q=${this.sanitizeTitle(buscado)}`">
                    <div class="bloco-mais-buscados background-attrs">
                        <h2 class="mono-title">{{buscado}} </h2>
                        <img :src="`/assets/img/buscados/${this.sanitizeTitle(buscado)}.webp`" class="img-fluid" width="600" height="290" :alt="`${buscado}`" />
                    </div>
                </a>
            </div>
        </div>
        <div v-if="!loader_mais_buscados" class="col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 xs:col-span-12 ver-mais-particulares" align="center">
            <!-- <a href="/comprar/carros" class="btn btn-default-tw ver-destaques">Ver mais</a> -->
        </div>
    </div>
</template>
<script>

    export default {
        components: {

        },
        data: function(){
            return {
                erro: '',
                marcas: [],
                buscados_home: [],
                loader_mais_buscados: true
            }
            
        },

        mounted(){
            this.loadStats();
        },

        methods:{
            loadStats: function(){
                axios.get('/api/v2/menu/stats')
                .then((response) => {
                    this.buscados_home = response.data.models;
                    this.loader_mais_buscados = false;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            sanitizeTitle: function(title) {
              var slug = "";
              // Change to lower case
              var titleLower = title.toLowerCase();
              // Letter "e"
              slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
              // Letter "a"
              slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
              // Letter "o"
              slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
              // Letter "u"
              slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
              // Letter "d"
              slug = slug.replace(/đ/gi, 'd');
              // Trim the last whitespace
              slug = slug.replace(/\s*$/g, '');
              // Change whitespace to "-"
              slug = slug.replace(/\s+/g, '-');
              
              return slug;
            },
            handleClick(ad) {
                const url = '/add-view-ads'; // Substitua pela URL da sua API
                const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

                axios.post(url, {
                    type: ad.id,
                    publicity: "ads",
                    action: "click",
                    url: ad.url
                }, {
                    headers: {
                        'X-CSRF-TOKEN': csrfToken
                    }
                })
                .then(response => {
                    console.log('Requisição AJAX concluída:', response.data);
                    // Após o AJAX, abre o link em uma nova aba
                    window.open(ad.url, '_blank');
                })
                .catch(error => {
                    console.error('Erro na requisição AJAX:', error);
                    // Em caso de erro, ainda assim abre o link
                    window.open(ad.url, '_blank');
                });
            },

        }
    }
    
</script>
