<template>
	<div class="bloco-menu-drop" style="padding: 0px;">
        <span>Por Marca</span>
        <div>
        	<li v-for="marca in marcas">
		        <a :href="`https://www.sulrevendas.com.br/comprar/carros?marca=${marca}`" class="icon-marca-menu">
		        	<img :src="`/assets/img/logos-marcas/${this.sanitizeTitle(marca)}.png`" class="img-fluid" width="30" height="23">{{marca}}
		        </a>
		    </li>	
        </div>
    </div>

    <div class="bloco-menu-drop" style="padding: 0px;">
        <span>Mais Buscados</span>
        <div>
        	  <li v-for="buscado in buscados">
	            <a :href="`https://www.sulrevendas.com.br/comprar/mais-buscados/${this.sanitizeTitle(buscado)}?template=v2&q=${this.sanitizeTitle(buscado)}`" class="text-white">{{buscado}}</a>
            </li>
        </div>
    </div>
</template>
<script>
   export default {
        components: {

        },
        data: function(){
            return {
                erro: '',
                marcas: [],
                buscados: [],
                loading: true
            }
            
        },

        mounted(){
            this.loadStats();
        },

        methods:{
            loadStats: function(){
                axios.get('/api/v2/menu/stats')
                .then((response) => {
                    this.marcas = response.data.brands;
                    this.buscados = response.data.models;
                    this.loading = false;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            sanitizeTitle: function(title) {
              var slug = "";
              // Change to lower case
              var titleLower = title.toLowerCase();
              // Letter "e"
              slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
              // Letter "a"
              slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
              // Letter "o"
              slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
              // Letter "u"
              slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
              // Letter "d"
              slug = slug.replace(/đ/gi, 'd');
              // Trim the last whitespace
              slug = slug.replace(/\s*$/g, '');
              // Change whitespace to "-"
              slug = slug.replace(/\s+/g, '-');
              
              return slug;
            },
        }
    }
    
</script>
