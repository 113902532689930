<template>

    <div class="right-filter">
        <div class="grid grid-cols-12">
            <div class="col-span-10 lg:col-span-10 md:col-span-10 sm:col-span-10 xs:col-span-10">
                <div class="titulo">
                    <h2><span>Resultado</span> Busca</h2>
                </div>
                <span class="busca-feita">Onix <i class="fa fa-solid fa-close" aria-hidden="true"></i> </span>
                <span class="busca-feita">Bento Gonçalves <i class="fa fa-solid fa-close" aria-hidden="true"></i> </span>
            </div>
            <div class="col-span-2 lg:col-span-2 md:col-span-2 sm:col-span-2 xs:col-span-2 icons-filter-top" align="center">
                <a href="">
                    <img src="/assets/img/list1.svg" class="icon-svg-search"> 
                </a>
                <a href="">
                    <img src="/assets/img/arrows1.svg" class="icon-svg-search"> 
                </a>
            </div>

            <div v-if="loader_destaques" class="col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 xs:col-span-12 padding-col loader-div-new-compoment" style="display: table; height: 350px;">
                <div class="loader loader--style1 loader-new-component" title="0">
                    <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
                        <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                        s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                        c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />
                        <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                        C22.32,8.481,24.301,9.057,26.013,10.047z">
                            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
            </div>

            <div v-if="!loader_destaques" class="col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 xs:col-span-12 padding-col loader-div-new-compoment" style="display: table; height: 350px;">
                <div class="bloco-width-cars padding-col" v-for="v in vehicles.slice(0, 10)">
                    <div class="bloco-veiculo">
                        <div class="">
                            <div class="">
                                <div class="imagem-veiculo background-attrs">
                                    <img src="/assets/img/selo1.png" v-if="v.change_in_exchange" title="Troco na Troca - Receba em dinheiro a diferença de valor da troca do seu carro." class="img-veiculo-selos">  
                                    <img src="/assets/img/selo2.png" v-if="v.veiculo_feirao" title="Troco na Troca" class="img-veiculo-selos">  
                                    <img src="/assets/img/selo3.png" v-if="v.lowPrice" title="Preço Baixo - Destacamos como Preço Baixo os veículos anunciados com valores de 5 a 15% abaixo da tabela KBB." class="img-veiculo-selos">  
                                    <div class="carousel-thumb">
                                        <img src="/assets/img/destaque.png" v-if="v.veiculo_destaque" title="Veículo em destaque no site." class="img-veiculo-destaque">             
                                        <Splide v-if="v.galerias" :options="{ rewind: false, drag: false, type: 'fade', padding: { left: '0rem', right: '0rem' }, preloadPages: 0}" aria-label="My Favorite Images" class="carousel-vue">
                                            <SplideSlide v-for="g in v.galerias">

                                                <div v-if="g.cdn" class="background-attrs img-slide-thumb-veiculo" :style="`background-image: url(${g.imagem_nome});`"></div>

                                                <div v-else-if="g.cdn_do" class="background-attrs img-slide-thumb-veiculo" :style="`background-image: url(https://srstorage.nyc3.digitaloceanspaces.com/veiculos/${v.veiculo_id}/md_${g.imagem_nome});`"></div>

                                                <div v-else-if="g.cdn_do_aws" class="background-attrs img-slide-thumb-veiculo" :style="`background-image: url(https://sraws.ams3.digitaloceanspaces.com/veiculos/${v.veiculo_id}/md_${g.imagem_nome});`"></div>

                                                <div v-else class="background-attrs img-slide-thumb-veiculo" :style="`background-image: url(https://static.sulrevendas.com.br/veiculos/${v.veiculo_diretorio}/${g.imagem_nome});`"></div>

                                            </SplideSlide>
                                        </Splide>  

                                        <Splide v-else :options="{ rewind: false, drag: false, type: 'slide', padding: { left: '1rem', right: '2rem' } }" aria-label="My Favorite Images" class="carousel-vue">
                                            <SplideSlide>
                                                <div class="background-attrs img-slide-thumb-veiculo" style="background-image: url(/images/sem-foto.png);"></div>
                                            </SplideSlide>
                                        </Splide>                  

                                        <!-- <a href=""><div class="background-attrs img-slide-thumb-veiculo" style="background-image: url(/images/sem-foto.png);"></div></a>
                                        <a href=""><div class="background-attrs img-slide-thumb-veiculo" style="background-image: url(/images/sem-foto.png);"></div></a> -->
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <a :href="`/veiculo/${v.veiculo_id}-${v.slug}-${v.veiculo_ano_modelo}`">
                                    <div class="dados-veiculo-bloco dados-veiculo-bloco-vue">
                                        <span class="ano">{{v.veiculo_ano_modelo}}</span>
                                        <h2 v-if="v.kbb_marca_nome">{{v.kbb_marca_nome}} {{v.kbb_modelo_nome}}</h2>
                                        <h2 v-else>{{v.fipe_marca_nome}} {{v.fipe_modelo_nome}}</h2>
                                        <span class="ficha-tecnica">{{v.kbb_versao_nome}}</span>
                                        <p v-if="v.site_publicar_valor" class="preco-veiculo-bloco">
                                            <div v-if="v.veiculo_valor_de">
                                                <span>de R$ 
                                                    <money3 style="width: 80%; pointer-events:none;" v-model="v.veiculo_valor_de" v-bind="config"></money3> 
                                                    <b></b> por 
                                                    <money3 style="width: 80%; pointer-events:none;" v-model="v.veiculo_valor_por" v-bind="config"></money3>
                                                </span>
                                            </div>
                                            <div v-else></div>
                                            <div v-if="v.veiculo_valor_de"></div>
                                            <div v-else>
                                                <span>R$ </span> 
                                                <money3 style="width: 80%; pointer-events:none;" v-model="v.veiculo_valor" v-bind="config"></money3>
                                            </div>

                                        </p>
                                        <p v-else class="preco-veiculo-bloco" style="font-size: 20px;">Consulte</p>

                                        <div class="div-inline-caract" v-if="v.veiculo_km">
                                            <span v-if="v.zero_km" class="icon-bloco-veiculo">
                                                <img src="/assets/img/svg/km_thumb.svg" class="svg-img">
                                                0 KM
                                            </span>
                                            <span v-else class="icon-bloco-veiculo">
                                                <img src="/assets/img/svg/km_thumb.svg" class="svg-img">
                                                <span v-if="v.veiculo_km">{{v.veiculo_km}}</span>
                                                <span v-else>Não informado</span>
                                            </span>
                                        </div>
                                        <div class="div-inline-caract" v-if="v.cambio_id">
                                            <span v-if="v.cambio_id === 1" class="icon-bloco-veiculo">
                                                <img src="/assets/img/svg/cambio_thumb.svg" class="svg-img">
                                                Manual
                                            </span>
                                            <span v-else-if="v.cambio_id === 2" class="icon-bloco-veiculo">
                                                <img src="/assets/img/svg/cambio_thumb.svg" class="svg-img">
                                                Automático
                                            </span>
                                            <span v-else class="icon-bloco-veiculo">
                                                <img src="/assets/img/svg/cambio_thumb.svg" class="svg-img">
                                                Automatizado
                                            </span>
                                        </div>                
                                        <br/>               
                                        <div class="bloco-action-call">
                                            <span>
                                                Mais informações <i class="fas fa-solid fa-arrow-right" aria-hidden="true"></i>
                                                <img src="/assets/img/whatsapp-link.png">
                                            </span>
                                        </div>
                                        <div v-if="v.anunciante" class="city-call">
                                            <span>{{ v.anunciante.cidade.nome }}, {{ v.anunciante.cidade.estado.sigla }}</span>
                                            <span><i class="fas fa-solid fa-heart" aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
            
            <div v-if="!loader_destaques" class="" align="center">
                <!-- <a href="/comprar/carros" class="btn btn-default-tw ver-destaques"><i class="fa fa-solid fa-fire" aria-hidden="true"></i> Ver todos destaques</a> -->
            </div>
        </div>
    </div>

</template>

<script>
    import VueNumberFormat from 'vue-number-format';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { component as VueNumber } from '@coders-tm/vue-number-format';
    import '@splidejs/vue-splide/css/core';
    import { Money3Component } from 'v-money3';

    export default {
        components: {
            Splide,
            SplideSlide,
            VueNumber,
            money3: Money3Component
        },

        data: function(){
            return {
                veiculo_valor: '12345.67',
                erro: '',
                vehicles: '',
                loader_destaques: true,
                config: {
                  masked: false,
                  prefix: '',
                  suffix: '',
                  thousands: '.',
                  decimal: ',',
                  precision: 2,
                  disableNegative: false,
                  disabled: true,
                  min: null,
                  max: null,
                  allowBlank: false,
                  minimumNumberOfCharacters: 0,
                  shouldRound: false,
                  focusOnRight: false,
                }
            }
            
        },

        mounted(){
            this.loadBusca();
        },

        methods:{
            loadBusca: function(){
                axios.get('/api/home/veiculos/destaques-da-semana')
                .then((response) => {
                    this.vehicles = response.data;
                    this.loader_destaques = false;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

        }
    }
    
</script>
