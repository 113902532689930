<template>
	<div class="destaques-xs" style="display: flex;flex-direction: row; flex-wrap: wrap; justify-content: center; align-items: center;">
        <div v-if="loader_destaques" class="col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 xs:col-span-12 padding-col loader-div-new-compoment" style="display: table; height: 350px;">
            <div class="loader loader--style1 loader-new-component" title="0">
                <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
                    <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />
                    <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                    C22.32,8.481,24.301,9.057,26.013,10.047z">
                        <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
        </div>

        <div v-if="!loader_destaques" class="bloco-width-cars padding-col" v-for="(v, index) in vehicles.slice(0, slice)">
            
            <div class="bloco-veiculo" v-if="ads_destaques_positions.includes(index)">
                <div v-for="ad in ads_destaques">
                    <a v-if="ad.position == index" class="add-statistic" target="_blank" :href="`${ad.url}`" :data-statistic-type="`${ad.id}`" data-statistic-publicity="ads" data-statistic-action="click" :data-statistic-url="`${ad.url}`" data-statistic-reference="">
                        <div class="car-item" style="height:390px">
                            <img class="cover d-sm-block" :src="`https://sraws.ams3.digitaloceanspaces.com/ads/${ad.image_lg}`" style="height:390px;" :alt="`${ad.name}`">
                        </div>
                    </a>
                </div>
            </div>

            <div class="bloco-veiculo" v-else>
                <div class="imagem-veiculo background-attrs">
                    <a :href="`/veiculo/${v.veiculo_id}-${v.slug}-${v.veiculo_ano_modelo}`" :target="this.target" title="Ver Mais">
                        <img src="/assets/img/destaque.png" v-if="v.veiculo_destaque" title="Veículo em destaque no site." class="img-veiculo-destaque" alt="Destaque">             
                        <div  v-if="v.galerias && v.galerias.length > 0">
                            <div v-for="g in v.galerias.slice(0,1)">

                                <div v-if="g.cdn" class="background-attrs img-slide-thumb-veiculo">
                                    <img :src="`${g.imagem_nome}`" width="650" height="400" alt="" />
                                </div>

                                <div v-else-if="g.cdn_do" class="background-attrs img-slide-thumb-veiculo">
                                    <img :src="`https://srstorage.nyc3.digitaloceanspaces.com/veiculos/${v.veiculo_id}/md_${g.imagem_nome}`" width="650" height="400" alt="" /></div>

                                <div v-else-if="g.cdn_do_aws" class="background-attrs img-slide-thumb-veiculo">
                                    <img :src="`https://sraws.ams3.digitaloceanspaces.com/veiculos/${v.veiculo_id}/md_${g.imagem_nome}`" width="650" height="400" alt="" /></div>

                                <div v-else class="background-attrs img-slide-thumb-veiculo">
                                    <img :src="`https://static.sulrevendas.com.br/veiculos/${v.veiculo_diretorio}/${g.imagem_nome}`" width="650" height="400" alt="" /></div>
                            </div>
                        </div>

                        <div v-else class="carousel-vue">
                            <div class="background-attrs img-slide-thumb-veiculo" style="background-image: url(/images/sem-foto.png); background-size: cover !important;"></div>
                        </div>  
                    </a>
                </div>
                
                <div class="dados-veiculo-bloco dados-veiculo-bloco-vue">
                    <span class="ano">{{v.veiculo_ano_modelo}}</span>

                    <!-- <svg v-if="!favoritados.includes(v.veiculo_id)" @click="favoritar(v.veiculo_id)" class="heart-bloco-thumb" viewBox="0 0 24 24" width="20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.7 4C18.87 4 21 6.98 21 9.76C21 15.39 12.16 20 12 20C11.84 20 3 15.39 3 9.76C3 6.98 5.13 4 8.3 4C10.12 4 11.31 4.91 12 5.71C12.69 4.91 13.88 4 15.7 4Z" stroke="#282828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    
                    <svg v-if="favoritados.includes(v.veiculo_id)" @click="desFavoritar(v.veiculo_id)" class="heart-bloco-thumb" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z" fill="#f10a0a"></path> </g></svg> -->

                    <a :href="`/veiculo/${v.veiculo_id}-${v.slug}-${v.veiculo_ano_modelo}`" target="_blank" title="Ver Mais">
                        <h2 v-if="v.kbb_marca_nome">{{v.kbb_marca_nome}} {{v.kbb_modelo_nome}}</h2>
                        <h2 v-else>{{v.fipe_marca_nome}} {{v.fipe_modelo_nome}}</h2>
                        <span class="ficha-tecnica">{{v.kbb_versao_nome}}</span>
                        <p v-if="v.site_publicar_valor" class="preco-veiculo-bloco">
                            <div v-if="v.veiculo_valor_de">
                                <span id="moneyJS">de R$ 
                                    <money3 style="width: 80%; pointer-events:none;background: transparent !important;" v-model="v.veiculo_valor_de" v-bind="config" aria-labelledby="moneyJS"></money3> 
                                    <b></b> por 
                                    <money3 style="width: 80%; pointer-events:none;background: transparent !important;" v-model="v.veiculo_valor_por" v-bind="config" aria-labelledby="moneyJS"></money3>
                                </span>
                            </div>
                            <div v-else></div>
                            <div v-if="v.veiculo_valor_de"></div>
                            <div v-else>
                                <span id="moneyJS">R$ </span> 
                                <money3 style="width: 80%; pointer-events:none;background: transparent !important;" v-model="v.veiculo_valor" v-bind="config" aria-labelledby="moneyJS"></money3>
                            </div>

                        </p>
                        <p v-else class="preco-veiculo-bloco" style="font-size: 20px;">Consulte</p>

                        <div class="div-inline-caract hidden-xs-vue" v-if="v.veiculo_km">
                            <span v-if="v.zero_km" class="icon-bloco-veiculo">
                                <img src="/assets/img/svg/km_thumb.svg" class="svg-img" alt="KM">
                                0 KM
                            </span>
                            <span v-else class="icon-bloco-veiculo">
                                <img src="/assets/img/svg/km_thumb.svg" class="svg-img" alt="KM">
                                <span v-if="v.veiculo_km">{{v.veiculo_km}}</span>
                                <span v-else>Não informado</span>
                            </span>
                        </div>
                        <div class="div-inline-caract hidden-xs-vue" v-if="v.cambio_id">
                            <span v-if="v.cambio_id === 1" class="icon-bloco-veiculo">
                                <img src="/assets/img/svg/cambio_thumb.svg" class="svg-img" alt="Câmbio">
                                Manual
                            </span>
                            <span v-else-if="v.cambio_id === 2" class="icon-bloco-veiculo">
                                <img src="/assets/img/svg/cambio_thumb.svg" class="svg-img" alt="Câmbio">
                                Automático
                            </span>
                            <span v-else class="icon-bloco-veiculo">
                                <img src="/assets/img/svg/cambio_thumb.svg" class="svg-img" alt="Câmbio">
                                Automatizado
                            </span>
                        </div>
                    </a>
                    <br/>
                    <div class="bloco-action-call">
                        <!-- <a href="#" class="btn btn-default-tw">Simular Financiamento</a> -->
                    </div>
                    <div v-if="v.anunciante && v.anunciante.cidade" class="city-call">
                        <span>{{ v.anunciante.cidade.nome }}, {{ v.anunciante.cidade.estado.sigla }}</span>
                        <span><i class="fas fa-solid fa-heart" aria-hidden="true"></i></span>
                    </div>
                </div>
            </div>  
        </div>

        <div v-if="!loader_destaques" class="" align="center">
            <!-- <a href="/comprar/carros" class="btn btn-default-tw ver-destaques"><i class="fa fa-solid fa-fire" aria-hidden="true"></i> Ver todos destaques</a> -->
        </div>
    </div>
</template>
<script>
    import VueNumberFormat from 'vue-number-format';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { component as VueNumber } from '@coders-tm/vue-number-format';
    import '@splidejs/vue-splide/css/core';
    import { Money3Component } from 'v-money3';


    export default {
        components: {
            Splide,
            SplideSlide,
            VueNumber,
            money3: Money3Component
        },
        data: function(){
            return {
                veiculo_valor: '12345.67',
                erro: '',
                vehicles: '',
                ads_destaques: [],
                ads_destaques_positions: [],
                loader_destaques: true,
                slice: 14, 
                config: {
                  masked: false,
                  prefix: '',
                  suffix: '',
                  thousands: '.',
                  decimal: ',',
                  precision: 2,
                  disableNegative: false,
                  disabled: true,
                  min: null,
                  max: null,
                  allowBlank: false,
                  minimumNumberOfCharacters: 0,
                  shouldRound: false,
                  focusOnRight: false,
                }
            }
            
        },

        mounted(){
            this.isMobile();
            this.searchAds();
            this.loadStats();
        },

        methods:{
            isMobile() {
                if (window.innerWidth < 768) {
                    this.slice = 14;
                }else if (window.innerWidth > 768 && window.innerWidth < 1100) {
                    this.slice = 9;
                }else if (window.innerWidth > 1100 && window.innerWidth < 1200) {
                    this.slice = 12;
                }else if (window.innerWidth > 1200 && window.innerWidth < 1300) {
                    this.slice = 12;
                }else if (window.innerWidth > 1300 && window.innerWidth < 1400) {
                    this.slice = 10;
                }else if (window.innerWidth > 1400 && window.innerWidth < 1500) {
                    this.slice = 10;
                }else if (window.innerWidth > 1500 && window.innerWidth < 1600) {
                    this.slice = 10;
                }else if (window.innerWidth > 1600 && window.innerWidth < 1700) {
                    this.slice = 12;
                }else if (window.innerWidth > 1700 && window.innerWidth < 1800) {
                    this.slice = 12;
                }else if (window.innerWidth > 1800 && window.innerWidth < 1900) {
                    this.slice = 14;
                }else if (window.innerWidth > 1900) {
                    this.slice = 14;
                }else{
                    this.slice = 14;
                }
            },
            loadStats: function(){
                axios.get('/api/home/veiculos/destaques-da-semana')
                .then((response) => {
                    this.vehicles = response.data;
                    this.loader_destaques = false;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            searchAds: function(){
                console.log("Buscou as Ads");

                axios.get('/api/v2/ads')
                .then((response) => {

                    response.data.forEach(subArray => {
                        // Iterar sobre cada objeto dentro do sub-array
                        subArray.forEach(item => {
                            if (value.session == 3) {
                                this.ads_destaques.push(value);
                                this.ads_destaques_positions.push(value.position);
                            }
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
            },

        }
    }
    
</script>
