<template>
  <div class="ad-container">
    <ins class="adsbygoogle"
         style="display:block"
         :data-ad-client="adClient"
         :data-ad-slot="adSlot"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
  </div>
</template>

<script>
export default {
  name: 'GoogleAd',
  props: {
    adClient: {
      type: String,
      required: true
    },
    adSlot: {
      type: String,
      required: true
    }
  },
  mounted() {
    // Load the Google Ads script
    if (typeof window.adsbygoogle === 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      script.async = true;
      document.head.appendChild(script);
    } else {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  },
  updated() {
    // Ensure ads are displayed correctly after re-renders
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
};
</script>

<style scoped>
.ad-container {
  margin: 20px 0;
}
</style>