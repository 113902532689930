require('./bootstrap');

import { createApp } from 'vue'
import Front from './components/Front'
import DropdownMenuStats from './components/DropdownMenuStats'
import AutocompleteHome from './components/AutocompleteHome'
import DestaquesHome from './components/DestaquesHome'
import RecemChegadosHome from './components/RecemChegadosHome'
import ParticularesHome from './components/ParticularesHome'
import MaisBuscadosHome from './components/MaisBuscadosHome'
import FilterLeft from './components/FilterLeft'
import ResultFilterLeft from './components/ResultFilterLeft'
import Recentes from './components/Recentes'
import Semelhantes from './components/Semelhantes'
import VueNumberFormat from 'vue-number-format'
import { createI18n } from 'vue-i18n'


const app = createApp({})
const appAutocomplete = createApp({})
const appDestaque = createApp({})
const appRecemChegado = createApp({})
const appParticular = createApp({})
const appMaisBuscado = createApp({})
const appFilter = createApp({})
const appResultFilterLeft = createApp({})
const appRecentes = createApp({})
const appSemelhantes = createApp({})


app.use(VueNumberFormat, {prefix: 'US$ ', decimal: ',', thounsand: '.'})
appDestaque.use(VueNumberFormat, {prefix: 'US$ ', decimal: ',', thounsand: '.'})


app.component('front-page', Front)
app.component('dropdown-menu-stats', DropdownMenuStats)
appAutocomplete.component('autocomplete-home', AutocompleteHome)
appDestaque.component('destaques-home', DestaquesHome)
appRecemChegado.component('recem-chegados-home', RecemChegadosHome)
appParticular.component('particulares-home', ParticularesHome)
appMaisBuscado.component('mais-buscados-home', MaisBuscadosHome)
appFilter.component('filter-left', FilterLeft)
appResultFilterLeft.component('result-filter-left', ResultFilterLeft)
appRecentes.component('recentes', Recentes)
appSemelhantes.component('semelhantes', Semelhantes)


app.mount('#app')
appAutocomplete.mount('#appAutocomplete')
appDestaque.mount('#appDestaque')
appRecemChegado.mount('#appRecemChegado')
appParticular.mount('#appParticular')
appMaisBuscado.mount('#appMaisBuscado')
appFilter.mount('#appFilter')
appResultFilterLeft.mount('#appResultFilterLeft')
appRecentes.mount('#appRecentes')
appSemelhantes.mount('#appSemelhantes')